import React from 'react';
import SEO from '../components/seo';
import { useStaticQuery, graphql } from 'gatsby';
import ControllerForm from '../components/ControllerForm';
import Layout from '../components/layout';

const ControllerPage = () => {

  const Data = useStaticQuery(graphql`
    query {
      allContentfulPages(filter: {slug: {eq: "professionals-aanmelden"}}) {
        edges {
          node {
            title
            slug
            metaTitle
            metaDescription
          }
        }
      }

      allContentfulFormFields {
        edges {
          node{
            controllerPageTitle
            controllerPageSubTitle
            placeOrderInputField
            nameFieldTextFormOne
            emailFieldTextFormOne
            phoneFieldTextFormOne
            buttonCaptionTextFormOne
            checkboxFieldTextFormOne
            placeOrderInputField
            supportTitlePageSidebar
            supportDescriptionPageSidebar{
              supportDescriptionPageSidebar
            }
            supportButtonLinkPageSidebar
            supportButtonCaptionPageSidebar
            worldwideTitlePageSidebar
            worldwideDescriptionPageSidebar{
              worldwideDescriptionPageSidebar
            }
            worldwideButtonCaptionPageSidebar
            worldwideButtonLinkPageSidebar
            callUsTextPageSidebar
            callUsNumberPageSidebar
            becomeADistributorText
            distributorButtonLinkPageSidebar
            distributorButtonCaptionPageSidebar
            mediaInquiriesTextPageSidebar
            mediaButtonCaptionPageSidebar
            mediaButtonLinkPageSidebar
          }
        }
      }
    }
  `);

  const metaData = Data.allContentfulPages.edges[0].node;

  const formData = Data.allContentfulFormFields.edges[0].node;

  return (
    <>
      <SEO
          title={metaData.metaTitle}
          description={metaData.metaDescription}
        />
        <Layout>
			<ControllerForm formData={formData}/>
    	</Layout>
    </>
  );

}

export default ControllerPage;